<template>
  <el-row type="flex" class="flex-wrap" :gutter="15">
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Information</p>
          <el-tag v-if="discount?.is_affiliate_code"> AFFILIATE CODE </el-tag>
        </div>
        <div class="discount-summary--content">
          <el-table :data="information" :show-header="false">
            <el-table-column width="120">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Customer Type</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="users" :show-header="false">
            <el-table-column width="200">
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <el-col :md="8" :lg="8">
      <div class="discount-summary">
        <div class="discount-summary--title">
          <p>Validity</p>
        </div>
        <div class="discount-summary--content">
          <el-table :data="validity" :show-header="false">
            <el-table-column>
              <template v-slot="scope">
                <p>
                  {{ scope.row.label }}
                </p>
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template v-slot="scope">
                <p class="text-black">
                  {{ scope.row.value }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "DiscountSummary",
  props: {
    discount: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    information() {
      return [
        {
          label: "Code",
          value: this.discount?.code ? this.discount?.code.toUpperCase() : "",
        },
        {
          label: "Discount Value",
          value:
            this.discount?.discount_type === "percentage"
              ? `${this.discount?.discount_value}%`
              : `${this.formatPrice(this.discount?.discount_value)}`,
        },
      ];
    },
    users() {
      let customer_info = [
        {
          label: "Customers Using Discount",
          value: this.discount?.users_count,
        },
      ];

      if (this.discount?.one_off) {
        customer_info.push({
          label: "Customers Can Use Discount",
          value: "Once",
        });
      } else {
        customer_info.push({
          label: "Linked To Customer Type",
          value:
            this.discount?.corporate_customer_persona_type_ids === null &&
            this.discount?.customer_persona_type_ids === null
              ? "-"
              : this.formatText(
                  this.discount?.corporate_customer_persona_type_name,
                ) || this.formatText(this.discount?.customer_persona_type_name),
        });
      }
      return customer_info;
    },
    validity() {
      return [
        {
          label: "Date Created",
          value: this.discount?.created_at
            ? this.formatDate(this.discount?.created_at, "do m, y")
            : "",
        },
        {
          label: "Expiry Date",
          value: this.discount?.expires_at
            ? this.formatDate(this.discount?.expires_at, "do m, y")
            : "Never",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-summary {
  border: 1px solid var(--eden-grey-senary);
  border-radius: 8px;
  padding: 15px 12px 15px;

  &--title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}
</style>
