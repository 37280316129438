<template>
  <div class="mt-5">
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <el-button
          v-if="!customersToUnlink.length"
          type="primary"
          @click="showCustomersLink = true"
          >Link Customers</el-button
        >
        <el-button
          v-else
          type="danger"
          plain
          @click="showCustomersUnlink = true"
          >Unlink Customers</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table
        v-if="pageData.length"
        :data="pageData"
        @selection-change="setCustomersToUnlink"
      >
        <el-table-column
          v-if="allowCustomersSelection"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Name </span>
          </template>
          <template v-slot="scope">
            <router-link
              class="text-primary"
              :to="{
                name: 'customers.individual',
                params: { id: scope.row.user_id },
              }"
              >{{
                scope.row.customer_name
                  ? formatName(scope.row.customer_name)
                  : "-"
              }}</router-link
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Email </span>
          </template>
          <template v-slot="scope">
            <span>
              {{
                scope.row.customer_email &&
                scope.row.customer_email.toLowerCase()
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Number of Times Used </span>
          </template>
          <template v-slot="scope">
            <p>{{ scope.row.uses || 0 }}</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Added on </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatDateAndTime(scope.row.created_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <span
              class="text-primary text-cursor text-underline"
              @click="setCustomer(scope.row)"
            >
              View details
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['superadmin'])" width="60">
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'remove',
                    user_id: scope.row.user_id,
                  }"
                  >Remove</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No data'" />
    </template>
    <discount-customers-link
      :show.sync="showCustomersLink"
      @success="$emit('updated')"
    />
    <discount-customers-unlink
      :show.sync="showCustomersUnlink"
      :customer-ids="customersToUnlink"
      @success="customersUnlinked"
    />
    <discount-customer-breakdown
      :show.sync="showDiscountCustomerDetails"
      :customer="customer"
    />
  </div>
</template>

<script>
import DiscountCustomersLink from "@/components/DiscountsAndRewards/Discounts/Discount/Linking/DiscountCustomersLink";
import DiscountCustomersUnlink from "@/components/DiscountsAndRewards/Discounts/Discount/Linking/DiscountCustomersUnlink";
import DiscountCustomerBreakdown from "@/components/DiscountsAndRewards/Discounts/Discount/DiscountCustomerBreakdown";

export default {
  name: "DiscountCustomers",
  components: {
    DiscountCustomerBreakdown,
    DiscountCustomersUnlink,
    DiscountCustomersLink,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    allowCustomersSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
      customer: {},
      customersToUnlink: [],
      showCustomersLink: false,
      showCustomersUnlink: false,
      showDiscountCustomerDetails: false,
    };
  },
  computed: {
    discountId() {
      return this.$route.params.id;
    },
    pageData() {
      if (this.query !== "") {
        const query = this.stringTrim(this.query);

        const customers = this.customers.filter((customer) => {
          const name = this.stringTrim(customer.customer_name);
          const email = this.stringTrim(customer.customer_email);

          return name.includes(query) || email.includes(query);
        });
        return this.sortArray({ data: customers, property: "customer_name" });
      }
      return this.sortArray({
        data: this.customers,
        property: "customer_name",
      });
    },
    title() {
      return this.pageData.length + " Customers";
    },
  },
  methods: {
    search(query) {
      this.query = query;
    },
    command(command) {
      this.customersToUnlink.push(command.user_id);
      this.showCustomersUnlink = true;
    },
    setCustomersToUnlink(customers) {
      this.customersToUnlink = [];
      customers.forEach((customer) => {
        this.customersToUnlink.push(customer.user_id);
      });
    },
    customersUnlinked() {
      this.$emit("updated");
      this.customersToUnlink = [];
    },
    setCustomer(customer) {
      this.customer = { ...customer };
      this.showDiscountCustomerDetails = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
