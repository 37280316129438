<template>
  <div>
    <eden-confirm-dialog
      :show.sync="setShow"
      :title="'Unlink customer(s)'"
      :button-text="'Unlink'"
      :button-type="'danger'"
      :button-status="unlinking"
      @confirm="unlink"
    >
      <p>Are you sure you want to unlink selected customers?</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import discounts from "@/requests/discounts";

export default {
  name: "DiscountCustomersUnlink",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customerIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      unlinking: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    close() {
      this.setShow = false;
    },
    unlink() {
      this.unlinking = true;

      const payload = {
        discount_id: this.$route.params.id,
        customer_ids: this.customerIds,
      };

      discounts
        .unlink(payload)
        .then((response) => {
          if (response.data.status) {
            this.unlinking = false;
            this.$message.success("Customers successfully unlinked.");
            this.$emit("success");
            this.close();
          }
        })
        .catch((error) => {
          this.unlinking = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
