<template>
  <el-dialog
    :title="displayDates ? 'Dates used' : formatName(customer.customer_name)"
    :visible.sync="setShow"
    @close="close"
    width="22%"
  >
    <el-row type="flex" v-if="!displayDates">
      <el-col :md="12">
        <p>Added on</p>
        <span>{{ formatDate(customer.created_at, "do m, y") }}</span>
      </el-col>
      <el-col :md="12" class="is-flex is-flex-column is-align-end">
        <p>Number of times used</p>
        <span>{{ customer.uses || 0 }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <p v-if="!displayDates">Dates used</p>
        <ul>
          <li v-for="(date, i) in customer.discount_used_timestamps" :key="i">
            <!-- <span v-if="!displayDates">{{ formatTime(date, "12hr") }} -</span> -->
            <span>{{ formatDate(date, "ddd do, m, y") }}</span>
          </li>
        </ul>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "DiscountCustomerDetails",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    displayDates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    close() {
      this.setShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: var(--eden-grey-primary);
  font-weight: 600;
}

span {
  font-size: 0.875rem;
  color: var(--eden-grey-tertiary);
  opacity: 0.9;
}

ul {
  margin-left: 0;
  padding-left: 20px;
}
</style>