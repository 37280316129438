<template>
  <div>
    <eden-page-header
      :title="'Discounts'"
      :subtitle="`${discount?.code && discount.code.toUpperCase()}`"
    >
      <template slot="actions">
        <discount-actions
          v-if="allowAccessFor(['admin', 'growth'])"
          :discount="discount"
          @update="actionUpdate"
        >
          <template slot="actions-trigger">
            <el-button type="plain"
              >Actions <i class="el-icon-arrow-down" />
            </el-button>
          </template>
        </discount-actions>
      </template>
    </eden-page-header>
    <discount-summary :discount="discount" />
    <el-row type="flex" class="mt-1">
      <el-col :span="24">
        <discount-customers
          :allow-customers-selection="allowCustomersSelection"
          :loading="loading"
          :customers="discount?.users"
          @updated="getDiscount"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import discounts from "@/requests/discounts";

import DiscountCustomers from "@/components/DiscountsAndRewards/Discounts/Discount/DiscountCustomers";
import DiscountSummary from "@/components/DiscountsAndRewards/Discounts/Discount/DiscountSummary";
import DiscountActions from "@/components/DiscountsAndRewards/Discounts/Discount/Actions/DiscountActions";

export default {
  name: "Discount",
  components: {
    DiscountActions,
    DiscountSummary,
    DiscountCustomers,
  },
  data() {
    return {
      loading: false,
      discount: {},
    };
  },
  computed: {
    discountId() {
      return this.$route.params.id;
    },
    allowCustomersSelection() {
      return (
        this.allowAccessFor(["admin", "growth"]) &&
        this.discount &&
        this.discount.corporate_customer_persona_type_ids === null &&
        this.discount.customer_persona_type_ids === null
      );
    },
  },
  created() {
    this.getDiscount();
  },
  methods: {
    getDiscount() {
      this.loading = true;
      discounts
        .get(this.discountId)
        .then((response) => {
          if (response.data.status) {
            this.discount = response.data?.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    actionUpdate(action) {
      switch (action) {
        case "edit":
          this.getDiscount();
          break;
        case "delete":
          this.$router.push({ name: "discounts.index" });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
