<template>
  <el-dialog
    title="Link Customer(s) to Discount"
    :visible.sync="setShow"
    width="30%"
    @close="close"
  >
    <div>
      <el-form :model="form" label-position="top" ref="form">
        <el-form-item label="Customer(s)" prop="customer_ids">
          <eden-customers-select
            :customers.sync="form.customer_ids"
            :multiple="true"
            :placeholder="'Search and select the customers'"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="close">Cancel</el-button>
      <el-button
        :disabled="!form.customer_ids.length"
        type="primary"
        @click="link"
        :loading="linking"
        >Save changes
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import discounts from "@/requests/discounts";
export default {
  name: "DiscountCustomersLink",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        customer_ids: [],
        discount_id: this.$route.params.id,
      },
      customers: [],
      linking: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    link() {
      this.linking = true;

      discounts
        .link(this.form)
        .then((response) => {
          if (response.data.status) {
            this.linking = false;
            this.$message.success(response.data.message);
            this.$emit("success");
            this.close();
          }
        })
        .catch((error) => {
          this.linking = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
