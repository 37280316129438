var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.search}},[(_vm.allowAccessFor(['admin', 'growth']))?_c('template',{slot:"actions"},[(!_vm.customersToUnlink.length)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showCustomersLink = true}}},[_vm._v("Link Customers")]):_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":function($event){_vm.showCustomersUnlink = true}}},[_vm._v("Unlink Customers")])],1):_vm._e()],2),(_vm.loading)?_c('eden-loader'):[(_vm.pageData.length)?_c('el-table',{attrs:{"data":_vm.pageData},on:{"selection-change":_vm.setCustomersToUnlink}},[(_vm.allowCustomersSelection)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"text-primary",attrs:{"to":{
              name: 'customers.individual',
              params: { id: scope.row.user_id },
            }}},[_vm._v(_vm._s(scope.row.customer_name ? _vm.formatName(scope.row.customer_name) : "-"))])]}}],null,false,1301124383)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Name ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.customer_email && scope.row.customer_email.toLowerCase())+" ")])]}}],null,false,1274877669)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Email ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.uses || 0))])]}}],null,false,26936922)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Number of Times Used ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.created_at, "do m, y")))])]}}],null,false,2748720871)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Added on ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-primary text-cursor text-underline",on:{"click":function($event){return _vm.setCustomer(scope.row)}}},[_vm._v(" View details ")])]}}],null,false,779982873)}),(_vm.allowAccessFor(['superadmin']))?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'remove',
                  user_id: scope.row.user_id,
                }}},[_vm._v("Remove")])],1)],1)]}}],null,false,1316700517)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No data'}})],_c('discount-customers-link',{attrs:{"show":_vm.showCustomersLink},on:{"update:show":function($event){_vm.showCustomersLink=$event},"success":function($event){return _vm.$emit('updated')}}}),_c('discount-customers-unlink',{attrs:{"show":_vm.showCustomersUnlink,"customer-ids":_vm.customersToUnlink},on:{"update:show":function($event){_vm.showCustomersUnlink=$event},"success":_vm.customersUnlinked}}),_c('discount-customer-breakdown',{attrs:{"show":_vm.showDiscountCustomerDetails,"customer":_vm.customer},on:{"update:show":function($event){_vm.showDiscountCustomerDetails=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }